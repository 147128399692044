import axios from 'axios'
import Cookies from 'js-cookie'

// const baseURL = 'testApi'
// const baseURL = 'https://rongyue.rongyuejiaoyu.com'

const instance = axios.create({ 
  baseURL: 'https://rongyue.rongyuejiaoyu.com', 
  timeout: 15000,
  headers: {
    'platform': 'pc',
    'shopid': '1'
  }
})

// 请求拦截器
instance.interceptors.request.use(config  => {
  const token_t = localStorage.getItem('vuex_token')  || ''
  
  // 动态设置headers（保留已有headers）
  config.headers  = {
    ...config.headers, 
    token: `${token_t}`
  }
  
  // 自动处理FormData边界
  if (config.data  instanceof FormData) {
    config.headers['Content-Type']  = 'multipart/form-data'
  } else {
    config.headers['Content-Type']  = 'application/json'
  }
  
  return config
})

// 响应拦截器
instance.interceptors.response.use(
  (res) => {
    // 对响应数据做点什么
    return res
  },
  (error) => {
    // 对响应错误做点什么
    return Promise.reject(error)
  }
)

export default instance
